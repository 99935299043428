<template>
    <div class="close-header-title no-con">
        <section class="pt_large pb_large shop-inner-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-heading">
                            <h1>
                                {{ totalRecords }} {{ $t('property_detail.stay') }}
                                <span v-if="fav">(Favorites)</span>
                            </h1>
                        </div>
                    </div>
                    <div class="box-column px-2" v-for="(property, index) in displayedProperties" :key="index">
                        <div class="top-partner-item top-partners__item buscont">
                            <div class="imgdet">
                                <div @click="propertyDetail(property.id)">
                                    <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls indicators
                                        background="#ababab" img-width="1024" img-height="480"
                                        style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart(index)"
                                        @sliding-end="onSlideEnd(index)">

                                        <b-carousel-slide v-for="(photo, index) in property.photos" :key="index"
                                            :img-src="photo.path">
                                        </b-carousel-slide>
                                    </b-carousel>
                                </div>
                                <div class="favorite-button-discount" v-if="property.discount_on_long_stay === 'yes'">
                                    <span class="checked-span">Discounted</span>
                                </div>
                                <div class="favorite-button">
                                    <button class="heart-btn" @click="addFavourite(property.id)">
                                        <i :class="['fa', isFavorited(property) ? 'fa-star' : 'fa-star-o']"
                                            aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="imgdet">
                                <div class="d-flex flex-column position-static card-discription">
                                    <div v-if="property.rating && property.rating.length > 0"
                                        class="d-flex rating-starts" style="margin-bottom: 15px;">
                                        <div class="rating-counter">
                                            <span>{{ calculateAverageRating(property.rating) }}</span>
                                        </div>
                                        <!-- <span class="reviews-expretion">Good</span>     -->
                                        <!-- <span v-if="property.rating">{{
                                            getReviewFeedback(calculateAverageRating(property.rating)) }}</span> -->

                                        <span class="reviews-expression" v-if="property.rating"> ({{
                                            property.rating.length }} {{ $t('property_detail.reviews') }})</span>
                                        <span class="reviews-text"></span>
                                    </div>
                                    <p class="mb-auto buz-name">{{ $t('property_detail.' + property.property_type) }}
                                    </p>
                                    <div class="detail-text" style="margin-top: 10px;">
                                        <p class="mb-2 card-text"><span>{{ property.city }} , {{ property.state }} ,
                                                {{ property.country }}</span>
                                            <!-- <span>{{ property["city" + currentLanguage] }}, {{ property["state" + currentLanguage] }}, {{
                                                    property["country" + currentLanguage] }}</span> -->
                                        </p>
                                    </div>
                                    <p class="mb-2 card-text"><span> </span></p>
                                    <div class="font-semibold text-n" v-if="property.discount_on_long_stay === 'yes'"
                                        style="margin-bottom: -20px;">
                                        <span>Discounts on Long Stays</span>
                                    </div>
                                    <div v-else-if="property.discountedPrice != null"
                                        class="font-semibold text-n strikethrough box-footer"
                                        data-cy="currency-quantile" style="margin-bottom: -20px;">
                                        <span class="text-xlightgrey">{{ currency }}</span>
                                        <span class="strikethrough">{{ property.formattedPrice }} {{
                                            property.discountedPrice }}</span><span class=" "> /
                                        </span><span class="">{{ $t('property_detail.night') }}</span>
                                    </div>
                                    <div class="d-flex box-footer">
                                        <div class="gap-y-xs">
                                            <div class="relative d-flex items-center gap-1 price whitespace-nowrap">
                                                <div class="font-semibold text-n" data-cy="currency-quantile"><span
                                                        class="text-xlightgrey">{{ currency }}</span><span> {{
                                                            property.formattedPrice }} {{ property.price }}</span><span
                                                        class=" "> /
                                                    </span><span class="">{{ $t('property_detail.night') }}</span></div>
                                                <br>
                                                <!-- <span class="tadd-date">Add dates</span> -->
                                            </div>
                                        </div>
                                        <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                            @click="propertyDetail(property.id)">{{ $t('property_detail.View') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row " v-if="!displayedProperties">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                        <div class="card left-card listing-bg-box" style="background: #a9a5a5 !important">
                            <div class="card-header" style="display:none"></div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <h5 class="text-black text-center">Properties not found.</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" style="display:none">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="displayedProperties.length < totalRecords" class="load-more-trigger"
                    @mouseover="handleMouseOver" ref="loadMoreTrigger" style="padding: 50px;">
                </div> -->
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <button v-if="next_page != null" @click="loadMore" class="btn btn-primary">
                            View More
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <b-modal id="modal-xl" size="xl" :title="`${totalRecords} Stays in your selected Range`"
            @shown="handleModalShown">
            <div class="show-map-modal">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="row p-3">
                            <div class="col-lg-4" v-for="(property, index) in displayedProperties" :key="index">
                                <div class="top-partner-item top-partners__item buscont"
                                    @click="propertyDetail(property.id)">
                                    <div class="imgdet">
                                        <div>
                                            <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls
                                                indicators background="#ababab" img-width="1024" img-height="480"
                                                style="text-shadow: 1px 1px 2px #333;"
                                                @sliding-start="onSlideStart(index)" @sliding-end="onSlideEnd(index)">

                                                <b-carousel-slide v-for="(photo, index) in property.photos" :key="index"
                                                    :img-src="photo.path">
                                                </b-carousel-slide>
                                            </b-carousel>
                                        </div>
                                        <div class="favorite-button-discount">
                                            <span class="checked-span">Discounted</span>
                                        </div>
                                        <div class="favorite-button">
                                            <button class="heart-btn">
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="imgdet">
                                        <div class="d-flex flex-column position-static card-discription">
                                            <div v-if="property.rating && property.rating.length > 1"
                                                class="d-flex rating-starts" style="margin-bottom: 15px;">
                                                <div class="rating-counter">
                                                    <span>{{ calculateAverageRating(property.rating) }}</span>
                                                </div>
                                                <!-- <span class="reviews-expretion">Good</span>     -->
                                                <span v-if="property.rating">{{
                                                    getReviewFeedback(calculateAverageRating(property.rating)) }}</span>

                                                <span class="reviews-expression" v-if="property.rating">({{
                                                    property.rating.length }} Reviews)</span>
                                                <span class="reviews-text"></span>
                                            </div>
                                            <p class="mb-auto buz-name">{{ $t('property_detail.' +
                                                property.property_type) }}
                                            </p>
                                            <div class="detail-text" style="margin-top: 10px;">
                                                <p class="mb-2 card-text"><span>{{ property["city"
                                                    + currentLanguage] }}, {{ property["state" + currentLanguage]
                                                        }}, {{
                                                            property["country" + currentLanguage] }}</span>
                                                </p>
                                            </div>
                                            <p class="mb-2 card-text"><span>
                                                    {{ property.user?.mobile_phone ?? '' }}</span></p>
                                            <div class="d-flex box-footer">
                                                <div class="gap-y-xs">
                                                    <div
                                                        class="relative d-flex items-center gap-1 price whitespace-nowrap">
                                                        <div class="font-semibold text-n" data-cy="currency-quantile">
                                                            <span class="">{{ currency }}</span><span
                                                                class="text-xlightgrey"> {{
                                                                    property.formattedPrice }} {{ property.price
                                                                }}</span><span class="text-xlightgrey"> /
                                                            </span><span class="text-xlightgrey">night</span>
                                                        </div>
                                                        <!-- <span class="tadd-date">Add dates</span> -->
                                                    </div>
                                                </div>
                                                <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                                    @click="propertyDetail(property.id)">View
                                                    detail<svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                                        height="1em" fill="none" viewBox="0 0 16 16">
                                                        <path fill="currentColor"
                                                            d="M12.667 12.667H3.333V3.333H8V2H3.333C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.593 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V8h-1.333zM9.333 2v1.333h2.394L5.173 9.887l.94.94 6.554-6.554v2.394H14V2z">
                                                        </path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 pl-0">
                        <div class="map-sec">
                            <div ref="map" style="height: 170%;width:630px;margin-top: 4%;"></div>
                            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11200.675829730526!2d-75.6876061!3d45.42609535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04ff4fe494ef%3A0x26bb54f60c29f6e!2sParliament+Hill!5e0!3m2!1sen!2sca!4v1528808935681" frameborder="0" style="border:0" allowfullscreen></iframe> -->
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'home',
    components: {
    },
    data() {
        return {
            fav: false,
            totalRecords: '',
            city: '',
            country: '',
            slide: [],
            sliding: null,
            properties: [],
            baseUrl: '',
            headers: '',
            userdata: '',
            user_id: '',
            lat: null,
            long: null,
            search_business: null,
            miles: '',
            page: 1,
            next_page: '',
            currentType: 'all',
            filterCheck: false,
            searchWord: '',
            searchList: [],
            showBanner: false,
            currentLanguage: '',
            map: null,
            address: '',
            sortOrder: 'low-to-high',
            isDisabledProperties: [],
            currency: '',
            displayedProperties: [],
            initialLoad: 30,
        }
    },
    beforeDestroy() {
        this.$root.$off('load-items');
        this.$root.$off('load-items1');
        this.$root.$off('load-items2');
        this.$root.$off('load-items3');
        this.$root.$off('load-items4');
        this.$root.$off('load-items5');
        this.$root.$off('load-items7');
        this.$root.$off('load-items8');
        this.$root.$off('load-items10');
        this.$root.$off('load-items11');
        this.$root.$off('load-items12');
    },
    mounted() {
        this.$root.$on('load-items', (city, country, state, sdate, edate, quantity, child, pet) => {
            this.loadItems(city, country, state, sdate, edate, quantity, '', '', '', '', '', '', '', '', '', child, pet);
        });
        this.$root.$on('load-items1', (type) => {
            if (type == 'All Types') {
                this.loadItems('', '', '', '', '', '', '', '', '', '');
            } else {
                this.loadItems('', '', '', '', '', '', type, '', '', '');
            }

        });
        this.$root.$on('load-items2', (min, max) => {
            this.loadItems('', '', '', '', '', '', '', min, max, '');
        });
        this.$root.$on('load-items10', (cur) => {
            this.loadItems('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', cur);
        });
        this.$root.$on('load-items3', (fav) => {
            if (fav) {
                this.fav = true;
            } else {
                this.fav = false;
            }
            this.loadItems('', '', '', '', '', '', '', '', '', '', '', '', '', '', fav);
        });
        this.$root.$on('load-items4', (range) => {
            this.sortByRating(range);
        });

        this.$root.$on('load-items5', (range) => {
            let order = '';
            if (range === 'low-to-high') {
                order = 'asc';
            } else if (range === 'high-to-low') {
                order = 'desc';
            }
            this.sortByPrice(order);
        });
        this.$root.$on('load-items7', (city, state, country) => {
            this.loadItems(city, state, country);
        });
        this.$root.$on('load-items8', (bed, bath) => {
            this.loadItems('', '', '', '', '', '', '', '', '', '', '', '', bed, bath, '', '')
        });
        this.$root.$on('load-items11', () => {
            let bath = localStorage.getItem('bath');
            let bed = localStorage.getItem('bed');
            let kit = localStorage.getItem('kitchen');
            let wifi = localStorage.getItem('wifi');
            let tv = localStorage.getItem('tv');
            this.loadItems('', '', '', '', '', '', '', '', '', kit, wifi, tv, bed, bath, '', '')
        });
        this.$root.$on('load-items12', () => {
            let dic = '1';
            this.loadItems('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', dic)
        });
        this.fetchUserLocation();
        this.loadItems();
        Vue.prototype.$EventBus.$on('changeLanguage', this.onLanguageChanged);
        this.sortByRating('desc');
    },
    methods: {
        // handleMouseOver() {
        //     console.log('dddd');
        //     this.loadMore();
        // }, 

        sortByPrice(order) {
            let sortedProperties = [...this.displayedProperties];
            sortedProperties.sort((a, b) => {
                let priceA = parseFloat(a.price);
                let priceB = parseFloat(b.price);

                if (isNaN(priceA)) priceA = Infinity;
                if (isNaN(priceB)) priceB = Infinity;

                if (order === 'desc') {
                    return priceB - priceA; // Sort descending (high to low)
                } else if (order === 'asc') {
                    return priceA - priceB; // Sort ascending (low to high)
                }

                return 0;
            });

            this.displayedProperties = sortedProperties;
        },

        sortByRating(order) {
            let sortedProperties = [...this.displayedProperties];

            if (order === 'desc' || order === 'asc') {
                sortedProperties.sort((a, b) => {
                    let ratingA = this.calculateAverageRating(a.rating) || -Infinity;
                    let ratingB = this.calculateAverageRating(b.rating) || -Infinity;

                    if (order === 'desc') {
                        return ratingB - ratingA;
                    } else if (order === 'asc') {
                        return ratingA - ratingB;
                    }

                    return 0;
                });
            }

            this.displayedProperties = sortedProperties;
        },
        // fetchCurrency() {
        //     axios.get('https://ipapi.co/currency/')
        //         .then(response => {
        //             this.currency = response.data;
        //             // console.log(this.pprice.price);
        //             // console.log(this.pprice.currency);
        //             // setTimeout(() => {
        //             //     this.covertcurency(this.pprice.price, this.pprice.currency, this.currency);
        //             // }, 2000);

        //         })
        //         .catch(error => {
        //             this.currency = 'USD';
        //             console.error('Error fetching currency:', error);
        //         });
        // },

        fetchLocationDetails(ip) {
            fetch('http://ip-api.com/json/' + ip)
                .then(response => response.json())
                .then(data => {
                    this.city = data.city;
                    this.country = data.country;
                })
                .catch(error => {
                    console.error('Error fetching IP details:', error);
                });
        },
        fetchUserLocation() {
            fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => {
                    const userLocation = data;
                    setTimeout(() => {
                        this.fetchLocationDetails(userLocation.ip);
                    }, 2000);
                })
                .catch(error => {
                    console.error('Error fetching IP address:', error);
                });
        },
        handleModalShown() {
            this.initializeMap();
        },
        updateSortOrder(newSortOrder) {
            this.sortOrder = newSortOrder;
        },
        initializeMap() {
            const originalUrl = this.$route.fullPath;
            let addresses = [];

            API.getallProperty(res => {
                res.data.data.forEach((item) => {
                    let address = `${item.city} ${item.state} ${item.country}`;
                    addresses.push(address);
                });
                const loader = new Loader({
                    apiKey: process.env.VUE_APP_GOOLE_PLACES_API_KEY,
                    version: 'weekly',
                    libraries: ['places'] // Add any additional libraries you need
                });

                loader.load().then(() => {
                    this.map = new window.google.maps.Map(this.$refs.map, {
                        center: { lat: 0, lng: 0 },
                        zoom: 30
                    });

                    const geocoder = new window.google.maps.Geocoder();
                    const bounds = new window.google.maps.LatLngBounds();

                    addresses.forEach((address) => {
                        geocoder.geocode({ 'address': address }, (results, status) => {
                            if (status === 'OK') {
                                const marker = new window.google.maps.Marker({
                                    map: this.map,
                                    position: results[0].geometry.location
                                });
                                bounds.extend(results[0].geometry.location);
                                this.map.fitBounds(bounds);
                            } else {
                                console.error('Geocode was not successful for the following reason: ' + status);
                            }
                        });
                    });
                });
            }, err => {
                console.log(err);
            });
        },
        calculateAverageRating(ratings) {
            if (ratings.length === 0) return 0; // Return 0 if no ratings available
            const sum = ratings.reduce((total, rating) => total + rating.rating, 0);
            return (sum / ratings.length).toFixed(1); // Calculate average rating
        },
        getReviewFeedback(averageRating) {
            if (averageRating >= 4.5) {
                return "Outstanding";
            } else if (averageRating >= 3.5) {
                return "Good";
            } else if (averageRating >= 2.5) {
                return "Average";
            } else if (averageRating >= 1.5) {
                return "Below Average";
            } else {
                return "Poor";
            }
        },
        onLanguageChanged(language) {
            if (language == 'en') {
                this.currentLanguage = '';
            } else {
                this.currentLanguage = '_' + language;
            }

        },
        onSlideStart(index) {
            this.slide[index] = 0
        },
        onSlideEnd(index) {
            this.slide[index] = this.properties[index].photos.length - 1
        },
        loadItems(city, country, state, startdate, enddate, guests, type, min, max, kit, wifi, tv, bed, bath, fav, child, pet, dic, currency = null) {
            let scur = localStorage.getItem('currency');
            if (currency) {
                this.currency = currency;
                this.fetchProperties(city, country, state, startdate, enddate, guests, type, min, max, kit, wifi, tv, bed, bath, fav, child, pet, dic, this.currency);
            } else if (scur) {
                this.currency = scur;
                this.fetchProperties(city, country, state, startdate, enddate, guests, type, min, max, kit, wifi, tv, bed, bath, fav, '', '', '', child, pet, dic);
            } else {
                axios.get('https://ipapi.co/currency/')
                    .then(response => {
                        this.currency = response.data;
                    })
                    .catch(error => {
                        this.currency = 'USD';
                    })
                    .finally(() => {
                        this.fetchProperties(city, country, state, startdate, enddate, guests, type, min, max, kit, wifi, tv, bed, bath, fav, child, pet, dic);
                    });
            }
        },
        fetchProperties(city, country, state, startdate, enddate, guests, type, min, max, kit, wifi, tv, bed, bath, fav, child, pet, dic) {
            const params = {
                currency: this.currency,
                page: this.page,
            };
            if (city) params.city = city;
            if (state) params.state = state;
            if (country) params.country = country;
            if (startdate) params.startdate = startdate;
            if (enddate) params.enddate = enddate;
            if (guests) params.guests = guests;
            if (type) params.type = type;
            if (min) params.min = min;
            if (max) params.max = max;
            if (bed) params.bed = bed;
            if (bath) params.bath = bath;
            if (kit) params.kit = kit;
            if (wifi) params.wifi = wifi;
            if (tv) params.tv = tv;
            if (fav) params.fav = fav;
            if (child) params.child = child;
            if (pet) params.pet = pet
            if (dic) params.dic = dic

            API.getAllProperties(params,
                data => {
                    this.properties = data.data;
                    this.displayedProperties = this.properties.data;
                    this.next_page = data.data.next_page_url
                    let Authorization = localStorage.getItem('isLoggedin');
                    if (Authorization) {
                        this.addFavProperties();
                    }
                },
                err => {
                    console.log(err);
                }
            );
        },

        addFavProperties() {
            API.getFavProperties(
                fevData => {
                    const favoritePropertyIds = fevData.data.data.map(item => item.property_id);
                    // this.next_page = fevData.next_page_url
                    this.properties.data.forEach(property => {
                        if (favoritePropertyIds.includes(property.id)) {
                            this.$set(property, 'isFavorited', true);
                        } else {
                            this.$set(property, 'isFavorited', false);
                        }
                        this.$set(this.isDisabledProperties, property.id, false);
                    });
                }
            );
        },

        propertyDetail(id) {
            this.$router.push({ name: 'property_detail', params: { id: id } });

            // let Authorization = localStorage.getItem('isLoggedin');
            // if (Authorization) {
            //     this.$router.push({ name: 'property_detail', params: { id: id } });

            // } else {
            //     this.$router.push({ name: 'guest_login' });
            // }
        },
        loadMore() {
            this.page++
            let currency;
            axios.get('https://ipapi.co/currency/')
                .then(response => {
                    currency = response.data;
                })
                .catch(error => {
                    // console.error('Error fetching currency:', error);
                    currency = 'USD';
                })
                .finally(() => {
                    const params = {
                        currency: currency,
                        page: this.page,
                    };
                    API.getAllProperties(params,
                        data => {
                            this.properties.data = [...this.properties.data, ...data.data.data];
                            this.next_page = data.data.next_page_url
                            let Authorization = localStorage.getItem('isLoggedin');
                            if (Authorization) {
                                this.addFavProperties();
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                });
        },

        addFavourite(propertyId) {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                const property = this.properties.data.find(prop => prop.id === propertyId);
                if (property) {
                    // Disable the button while operation is in progress
                    this.$set(this.isDisabledProperties, propertyId, true);

                    API.addFavourite(propertyId, res => {
                        // this.$set(property, 'isFavorited', true);
                        if (res.data.message == 'Property deleted successfully') {
                            this.$set(property, 'isFavorited', false);
                        } else {
                            this.$set(property, 'isFavorited', true);
                        }
                        // Enable the button after operation completes (if needed)
                        this.$set(this.isDisabledProperties, propertyId, false);
                    }, err => {
                        console.log(err);
                        // Enable the button in case of error (if needed)
                        this.$set(this.isDisabledProperties, propertyId, false);
                    });
                }
            } else {
                this.$bvModal.show('modal-4');
                // this.$router.push({ name: 'guest_login' });
            }


        },
        isFavorited(property) {
            return property.isFavorited === true;
        },

        isDisabled(property) {
            // Check if the button should be disabled
            return this.isFavorited(property) || this.isDisabledProperties[property.id];
        },
        serachBusiness() {
            var miles = this.miles;
            if (miles == null || miles == '') {
                miles = null;
            }
            this.isDisabled = true
        }
    },
    created() {
        // this.fetchCurrency();
        this.$root.$on('sort-order-changed', this.updateSortOrder);
    },
}
</script>
<style scoped>
a {
    text-decoration: none;
}

.container-fluid.page-body-wrapper.client-dashbboard {
    padding-top: 75px !important;
}

.main-heading {
    margin-bottom: 16px;
}

.main-heading h1 {
    font-weight: 600;
    line-height: 1;
    font-size: 18px;
    margin: 0;
}

header {
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    z-index: 1111;
}

.top-partner-item.top-partners__item.buscont {
    background: #fff;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}


.home-page-sec .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1832px;
}

.home-page-sec .row .top-partner-item {
    flex-direction: column;
    border-radius: 12px !important;
}

.home-page-sec .row .top-partner-item .imgdet {
    width: 100%;
}

.home-page-sec .row .top-partner-item .imgdet .top-partner-item__image-wrapper {
    width: 100% !important;
    padding: 0;
}

html .home-page-sec .row .top-partner-item .imgdet .top-partner-item__image-wrapper img.buz-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.home-page-sec .row .top-partner-item .imgdet .card-discription {
    padding: 10px;
}

header {
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    z-index: 1111;
}

.top-partner-item.top-partners__item.buscont {
    background: #fff;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.hd-wrap span#RegisterDropdown {
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

.hd-wrap span#RegisterDropdown:hover,
.hd-wrap span#RegisterDropdown:focus {
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
}

.hd-wrap #LoginDropdown {
    color: #fff;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

.hd-wrap #LoginDropdown:hover {
    color: #eee;
}

span#RegisterDropdown span {
    color: #fff !important;
}

span#LoginDropdown span {
    color: #fff !important;
    -webkit-transition: .35s;
    transition: .35s;
}

span#LoginDropdown span:hover {
    color: #eee !important;
}

html[data-scroll="0"] .logo-header a img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html:not([data-scroll="0"]) body {
    padding-top: 3em;
}

html:not([data-scroll="0"]) header {
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    z-index: 2;
}

html:not([data-scroll="0"]) .hd-wrap span#RegisterDropdown {
    color: #42aaff !important;
    background-color: transparent !important;
    border: 2px solid #42aaff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

html:not([data-scroll="0"]) .hd-wrap #LoginDropdown {
    color: #6c757d;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

html:not([data-scroll="0"]) span#RegisterDropdown span {
    color: #6c757d !important;
}

html:not([data-scroll="0"]) span#LoginDropdown span {
    color: #6c757d !important;
}

/* Ends here */

.right-bdr {
    border-right: 1px solid #efefef;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(239, 239, 239);
    margin-bottom: 0px;
}

.gray-divider {
    width: 100%;
    height: 1px;
    background: #efefef;
}

.some-cmnt {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding-top: 15px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    line-height: 25px;

}

.listing-bg-box {
    width: 100%;
    background: #fff;
    box-shadow: none;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 20px 15px;
    display: inline-block;
}

.progress .progress-bar {
    border-radius: 30px;
    box-shadow: none;
    position: relative;
    animation: animate-positive 2s;
    background: #70d3ff !important;
}

.time-hours {
    color: #363f4d;
    font-size: 15px;
    margin-top: 8px;
    letter-spacing: 1px;
    font-weight: 700;
    display: flex;
}

.time-hours span {
    color: #363f4d;
    /* border-right: 1px solid #ccc; */
    padding-right: 5px;
    font-weight: 700;
}

.progress {
    border-radius: 50px;
}

.progress .progress-bar {
    border-radius: 0px;
}

.card-discription .rating-starts {
    margin-bottom: 4px;
    align-items: center;
    column-gap: 4px;
}

.card-discription .rating-starts .rating-counter {
    padding-left: 4px;
    padding-right: 4px;
    background-color: #e2ebfa;
    border-radius: 5px;
    font-weight: 600;
    color: #000;
}

.card-discription .rating-starts span.reviews-expretion {
    font-weight: 600;
}

.card-discription .rating-starts span {
    font-size: 14px;
}

.card-discription .rating-starts span.reviews-text {
    font-weight: 400;
    font-size: 12px;
}

.shop-inner-section .container {
    max-width: 1832px;
}

.box-footer .card-blue-btn {
    background-color: #70d3ff;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.box-footer .card-blue-btn:hover {
    border: 1px solid #70d3ff;
    background: #fff;
    color: #70d3ff;
}

.box-footer {
    justify-content: space-between;
    margin-top: 20px;
}

.box-footer span {
    font-weight: 600;
}

.box-footer span.text-xlightgrey {
    color: #c0c2c4;
}

.box-footer span.tadd-date {
    font-size: 14px;
    line-height: 1.25rem;
    max-width: 170px;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 400;
}

.gap-y-xs {
    display: flex;
    align-items: center;
}

.favorite-button {
    position: absolute;
    right: .75rem;
    top: 1rem !important;
}

.favorite-button-discount {
    position: absolute;
    left: .75rem;
    top: 1rem !important;
}

.favorite-button button.heart-btn {
    border-radius: 100px;
    height: 32px;
    width: 32px;
    border: 0;
    background: #70d3ff;
    color: #fff;
    position: relative;
    z-index: 2;
    display: none;
}
.top-partner-item.top-partners__item.buscont>div.imgdet:hover .favorite-button button.heart-btn {
    display: block;
}
.checked-span {
    padding: 4px 6px;
    background-color: #70d3ff;
    color: #000;
    font-size: 12px;
    line-height: normal;
    border-radius: 6px;
    box-shadow: 0 0 6px 0px #70d3ff;
}

.b-carousel img,
.carousel .carousel-inner .carousel-item img {
    height: 215px !important;
    object-fit: cover !important;
}

@media only screen and (max-width: 767px) {
    .box_title a p {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 575px) {
    .right-bdr {
        border-right: 0;
        border-right-width: 0;
    }
}

#home_search .pl-0 {
    padding-left: 0px;
}

#home_search .pr-0 {
    padding-right: 0px;
}

.search-bar form {
    margin-bottom: 50px;
    float: left;
    width: 100%;
    background: #fff;
    box-shadow: -2px 0px 19px 0px #cccccc;
    padding: 15px;
    border-radius: 40px;
}

.search-bar button {
    width: 100%;
    height: 50px;
    padding: 15px 37px;
    margin-left: -5px;
    background: #70d3ff;
    border: 1px solid#70d3ff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.search-bar .fa-search {
    color: #fff;
}

.search-bar .fa-spinner {
    color: #fff;
}

.search-bar img {
    padding-top: 100px;
    padding-bottom: 50px;
    width: 150px;
}

.custom-srch-box {
    height: 50px;
    width: 100%;
    border: 1px solid #f9f0f0;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding-left: 15px;
}

#home_search select {
    height: 50px;
    width: 99%;
    border: 1px solid #f9f0f0;
    border-radius: 0px;
    padding-left: 15px;
    background: #fff;
    border-left: 0px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
}

#home_search select:focus {
    outline: none;
}

.disabled {
    pointer-events: none;
}

.top-partner-item__image {
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    z-index: 0;
    width: 120px;
}

.card-discription .rating-starts span {
    font-size: 14px;
}

.card-discription .rating-starts span.reviews-text {
    font-weight: 400;
    font-size: 12px;
}

.shop-inner-section .container {
    max-width: 1832px;
}

.box-footer {
    justify-content: space-between;
    margin-top: 20px;
}

.box-footer span {
    font-weight: 600;
}

.box-footer span.text-xlightgrey {
    color: #c0c2c4;
}

.box-footer span.tadd-date {
    font-size: 14px;
    line-height: 1.25rem;
    max-width: 170px;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 400;
}

.gap-y-xs {
    display: flex;
    align-items: center;
}

.favorite-button {
    position: absolute;
    right: .75rem;
    top: 1rem !important;
}

.b-carousel img,
.carousel .carousel-inner .carousel-item img {
    height: 215px !important;
    object-fit: cover !important;
}

.box-column {
    width: 16.6%;
    margin-bottom: 24px;
}

.card-discription .detail-text p {
    margin: 0 !important;
}

.card-discription .detail-text {
    min-height: 42px;
}

@media only screen and (max-width: 1719px) {

    .b-carousel img,
    .carousel .carousel-inner .carousel-item img {
        height: 215px !important;
        object-fit: cover !important;
    }


}

@media only screen and (max-width: 1719px) {
    .box-column {
        width: 20%;
    }
}

@media only screen and (max-width: 1399px) {
    .box-column {
        width: 25%;
    }
}

@media only screen and (max-width: 1149px) {
    .box-column {
        width: 33%;
    }
}

@media only screen and (max-width: 767px) {
    .box_title a p {
        font-size: 16px !important;
    }

    .box-column {
        width: 50%;
    }
}

@media only screen and (max-width: 574px) {
    .right-bdr {
        border-right: 0;
        border-right-width: 0;
    }

    .box-column {
        width: 100%;
    }

    .d-flex.flex-column.position-static {
        min-height: inherit;
    }
}

#home_search .pl-0 {
    padding-left: 0px;
}

#home_search .pr-0 {
    padding-right: 0px;
}

.search-bar form {
    margin-bottom: 50px;
    float: left;
    width: 100%;
    background: #fff;
    box-shadow: -2px 0px 19px 0px #cccccc;
    padding: 15px;
    border-radius: 40px;
}

.search-bar button {
    width: 100%;
    height: 50px;
    padding: 15px 37px;
    margin-left: -5px;
    background: #70d3ff;
    border: 1px solid#70d3ff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.search-bar .fa-search {
    color: #fff;
}

.search-bar .fa-spinner {
    color: #fff;
}

.search-bar img {
    padding-top: 100px;
    padding-bottom: 50px;
    width: 150px;
}

.custom-srch-box {
    height: 50px;
    width: 100%;
    border: 1px solid #f9f0f0;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding-left: 15px;
}

#home_search select {
    height: 50px;
    width: 99%;
    border: 1px solid #f9f0f0;
    border-radius: 0px;
    padding-left: 15px;
    background: #fff;
    border-left: 0px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
}

#home_search select:focus {
    outline: none;
}

.disabled {
    pointer-events: none;
}


@media (min-width: 1025px) {
    .top-partner-item__image {
        width: 138px;
    }
}

.top-partner-item__image_close {
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    z-index: 0;
    width: 120px;
}

@media (min-width: 1025px) {
    .top-partner-item__image_close {
        width: 138px;
    }
}

.buscont {
    /* display: flex; */
    /* Standard syntax */
    /* display: -webkit-flex; */
    /* Safari */
    margin-bottom: 24px;
    box-shadow: 0px 0px 8px 1px rgb(212 212 212 / 39%);
    padding: 8px !important;
    border-radius: 12px;
    padding-bottom: 24px !important;
}

.buscont:hover {
    cursor: pointer;
}

.imgdet {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}

.top-partner-item__image-wrapper {
    position: relative;
    text-align: center;
}

.buz-name {
    background: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin-bottom: 2px !important;
}

.buz-time {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #444141b0;
    padding: 2px 15px;
    border-radius: 5px;
}

.buz-timing {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins';
    position: absolute;
    bottom: 16px;
    right: 15px;
    border-top: 1px solid #efefef;
    background: #4ebaff;
    padding: 5px;
    color: white !important;
    border-top-left-radius: 5px;
}

.closeclass {
    filter: opacity(0.5);
}

/* img.buz-img{
        border-radius: 7px !important;
        width: 200px;
        height: 150px;
        margin: 10px;
        object-fit: fill;
        -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
    } */
img.buz-img {
    /* border-radius: 7px !important; */
    width: 100%;
    height: 150px;
    margin: 10px;
    object-fit: fill;
    /* -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important; */
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 0;
}

.left-card {
    border-right: none !important;
}

@media (min-width: 768px) and (max-width: 991px) {
    .top-partner-item__image-wrapper {
        width: 123px !important;
        height: 95px !important;
    }

    img.buz-img {
        width: 100px;
        height: 75px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .top-partner-item__image-wrapper {
            width: 123px !important;
            height: 95px !important;
        }

        img.buz-img {
            width: 100%;
            height: 75px;
        }

        .buz-time[data-v-b0b0db8e] {
            font-size: 12px;
        }

        .buscont[data-v-b0b0db8e] {
            min-height: 200px;
        }
    }

    .buscont[data-v-b0b0db8e] {
        min-height: 200px;
    }
}

@media (max-width: 991px) {
    .pt_large {
        padding-top: 110px !important;
    }
}

@media (min-width: 992px) {
    .pt_large {
        padding-top: 110px !important;
    }
}

.top-partner-item.top-partners__item.buscont img.buz-img {
    margin: 0px;
    border: 0px !important;
    box-shadow: none !important;
    height: 170px;
    object-fit: cover;
    border-radius: 0px !important;
}

.top-partner-item.top-partners__item.buscont {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pt_large.pb_large.shop-inner-section {
    background: #fff;
    padding-top: 0 !important;
}

.top-partner-item.top-partners__item.buscont {
    padding: 0px !important;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e7e7e8;
    height: 100%;
    margin: 0;
}

.top-partner-item.top-partners__item.buscont>div.imgdet {
    flex: unset;
}


.top-partner-item__image-wrapper {
    padding-bottom: 35px;
}

.shop-inner-section .page-banner {
    margin-bottom: 50px;
    /* background-image: url(https://paseomarket.com/storage/users/cover_image/sQ7LyPD3SuXgAdQWdybHtNzJhFZzEgELhRScw8DR.jpg); */
    background-image: url(/images/hero-bg.png?156791b69b3ea459aefaeb2e1ccc3ac6);
    height: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    position: relative;
    z-index: 1;
}

.shop-inner-section .page-banner i {
    right: 13px;
    position: absolute;
    color: #fff;
    top: 10px;
    cursor: pointer;
}

.shop-inner-section .page-banner .banner_content {
    /* padding: 50px 0px;
        text-align: center;
        background-color: #4ebaff; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-inner-section .page-banner .banner_content:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000001c;
    top: 0;
    left: 0;
    z-index: -1;
}

.shop-inner-section .page-banner .banner_content h2 {
    color: #fff;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
        0px -4px 10px rgba(255, 255, 255, 0.3);
}

@media (min-width: 1024px) {
    .top-partner-item__image-wrapper {
        padding-bottom: 0px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .top-partner-item__image-wrapper {
        padding-bottom: 0px;
    }
}

.top-partner-item.top-partners__item.buscont>div.imgdet:nth-child(2) {
    height: calc(50% - 12px);
}

.d-flex.flex-column.position-static {
    padding: 12px;
    height: 100%;
    justify-content: space-around;
    min-height: 191px;
}

.float-left {
    float: none !important;
}

@media (max-width:991px) {
    .top-partner-item.top-partners__item.buscont>div.imgdet {
        width: 50%;
    }

    .top-partner-item__image-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 1023px) {
    .top-partner-item__image-wrapper {
        padding-bottom: 0;
    }

    .card-discription .card-text {
        font-size: 12px;
    }

    .d-flex.flex-column.position-static {
        padding-bottom: 12px;
    }

    .top-partner-item__image-wrapper {
        height: inherit !important;
    }
}

@media (max-width: 768px) {
    .shop-inner-section .page-banner .banner_content {
        padding: 20px 0px;
    }
}

@media (max-width: 574.98px) {
    .top-partner-item.top-partners__item.buscont {
        flex-wrap: wrap;
    }
}

@media (max-width:991px) {

    .top-partner-item__image-wrapper {
        width: 100% !important;
        padding-right: 0;
    }

    .top-partner-item.top-partners__item.buscont>div.imgdet,
    img.buz-img {
        width: 100%;
    }

    .d-flex.flex-column.position-static {
        padding: 15px;
    }
}
</style>
<style scoped>
.google-places-autocomplete {
    position: relative;
}

#autocomplete {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.suggestions-list li {
    padding: 8px;
    cursor: pointer;
}

.suggestions-list li:hover {
    background-color: #f0f0f0;
}


.strikethrough {
    text-decoration: line-through;
}
</style>